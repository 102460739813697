import { render, staticRenderFns } from "./Slogan.vue?vue&type=template&id=5007475f&scoped=true&"
var script = {}
import style0 from "./Slogan.vue?vue&type=style&index=0&id=5007475f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5007475f",
  null
  
)

export default component.exports